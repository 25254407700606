import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import window from '@/common/mixins/window'
import AOS from 'aos'
import axios from '@/auth/axios'
import { StripePlugin } from '@vue-stripe/vue-stripe'
import vueTabevents from 'vue-tabevents'
import App from './App.vue'
import router from './router'
import store from './store'
// Global Components
import './global-components'
// Import the Auth0 configuration
import { domain, clientId } from '../auth_config.json'
// Import the plugin here
import { Auth0Plugin } from './auth'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'sweetalert2/dist/sweetalert2.min.css'
import 'aos/dist/aos.css'

const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT,
  apiVersion: process.env.VUE_APP_API_VERSION,
  locale: process.env.VUE_APP_LOCALE,
}

Vue.use(vueTabevents)

Vue.use(StripePlugin, options)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Composition API
Vue.use(VueCompositionAPI)
Vue.prototype.$axios = axios
// import core styles
require('@core/scss/core.scss')

Vue.use(VueSweetalert2)

// Composition API
Vue.use(VueCompositionAPI)
Vue.mixin(window)
// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    )
  },
})
// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
const app = null
// auth.initApp(Vue, app, router, store, App)
new Vue({
  Vue,
  app,
  router,
  store,
  App,
  mounted() {
    AOS.init({
      // Global settings:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1500, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    })
  },
  render: h => h(App),
}).$mount('#app')
