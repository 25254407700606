// define a mixin object
export default {
  created() {
    window.addEventListener('resize', this.myEventHandler)
  },
  destroyed() {
    window.removeEventListener('resize', this.myEventHandler)
  },
  methods: {
    myEventHandler() {
      this.width = window.innerWidth
      this.height = window.innerHeight
    },
  },
  data() {
    return {
      width: 0,
      height: 0,
    }
  },
}
